import { gql } from "@apollo/client"

export const SEARCH_VEHICLE = gql`
query SearchVehicle($keyword: String) {
    searchVehicle(keyword: $keyword) {
        year
        make {
            name
        }
        model {
            name
            year_start
            year_end
        }
        modelGeneration {
            name
            year_start
            year_end
        }
        modelVariant {
            name
        }
        modelTrim {
            name
        }
        attributes {
            description
        }
    }
}
`
export const GET_COMPS = gql`
query GetCompsFromTaxonomy($input: VehicleCompsInput) {
    getCompsFromTaxonomy(input: $input) {
        count
        similar {
            title
            price
            price_usd
            price_currency
            status
            high_bid_price
            lot_date
            mileage_in_miles
            image_url
            location_place_name
            listing_type
            attributes {
                kind
                value
            }
        }
    }
}
`
export const SEARCH_BY_NAME = gql`
query searchVehicleByTaxonomyName($make: String!, $model: String!, $year: String, $modelGeneration: String, $modelVariant: String) {
    searchVehicleByTaxonomyName(make: $make, model: $model, year: $year, modelGeneration: $modelGeneration, modelVariant: $modelVariant) {

        title
        price
        price_usd
        price_currency
        status
        high_bid_price
        lot_date
        mileage_in_miles
        image_url
        location_place_name
        listing_type
        attributes {
            kind
            value
        }
        
    }
}
`

export const MARKET_WIDGET = gql`
  mutation MarketWidGet($input: CreateMarketWidgetTaxonomyNameInput!) {
    createMarketWidgetFromTaxonomyName(input: $input) {
      data {
        id
        hashId
        marketId
        url
        market {
          description
        }
      }
      code
      message
      success
    }
  }
`;


export const MAKE_MODEL = gql`
  query MakesQuery {
    makes(options: { type: Automobile }) {
      id
      name
      models {
        id
        name
        make_id
        modelVariant {
          id
          name
          make_id
          model_id
          modelTrim {
            id
            name
          }
        }
        modelGeneration {
          id
          name
          year_start
          year_end
        }
      }
    }
    getMarketList {
      id
      name
    }
  }
`
